import styles from "./PodcastArchiveHeader.module.css";
import { handleTrackClick } from "~lib/analytics/handleTrackClick";

const bgAssets = import.meta.glob("./*/*.jpg", {
  as: "url",
  eager: true,
});

export function PodcastArchiveHeader({
  title = "",
  description = "",
  slug = "",
  rss_url = "",
  spotify_url = "",
  apple_url = "",
}) {
  const background = bgAssets[`./bg/${slug}.jpg`];
  const coverArt = bgAssets[`./cover/${slug}.jpg`];
  const feedUrls = { rss_url, spotify_url, apple_url };

  return (
    <header className={styles.header}>
      <div className={styles.headerArt}>
        <img
          src={background}
          alt=""
          fetchpriority="high"
          loading="eager"
          role="presentation"
          aria-hidden="true"
        />
      </div>
      <div className={styles.headerContent}>
        <div className={styles.coverArt}>
          <img
            src={coverArt}
            alt={`Cover art for ${title}`}
            fetchpriority="high"
            loading={"eager"}
          />
        </div>
        <div className={styles.info}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          <PodcastFeedCallout {...feedUrls} title={title} />
        </div>
      </div>
    </header>
  );
}

function PodcastFeedCallout({ title, rss_url = "", spotify_url = "", apple_url = "" }) {
  return (
    <aside className={styles.callout}>
      <strong className={styles.calloutTitle}>Subscribe to listen</strong>
      <ul className={styles.calloutLinks}>
        <li>
          <a
            href={apple_url}
            className={styles.calloutLink}
            title={`${title} on Apple Podcasts`}
            rel="noopener nofollow"
            target="_blank"
            onClick={(e) => handleTrackClick("podcast-subscribe-apple", e)}
          >
            Apple
          </a>
        </li>
        <li>
          <a
            href={spotify_url}
            className={styles.calloutLink}
            title={`${title} on Spotify`}
            rel="noopener nofollow"
            target="_blank"
            onClick={(e) => handleTrackClick("podcast-subscribe-spotify", e)}
          >
            Spotify
          </a>
        </li>
        <li>
          <a
            href={rss_url}
            className={styles.calloutLink}
            title={`${title} via RSS`}
            rel="noopener nofollow"
            target="_blank"
            onClick={(e) => handleTrackClick("podcast-subscribe-rss", e)}
          >
            RSS
          </a>
        </li>
      </ul>
    </aside>
  );
}

export default PodcastArchiveHeader;
